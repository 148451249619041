.ant-modal-close {
    display: none;
}
.ant-modal {
    top: 20%;
}

/*body inside content*/
.ant-modal-content {
    background: #1F2428;
    border: 1px solid #303030;
    border-radius: 4px;
    padding: 35px 46px;
}
.ant-modal-body {
    padding: 0;
}

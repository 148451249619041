.ant-input-affix-wrapper{

    background-color: #4B5563;
}

.ant-input{
    color: #000;
    background-color: #4B5563;
}


.ant-input-group-addon{
    background-color: #4B5563;
}

.ant-input{
    color: #fff;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    color: #9CA3AF;
}
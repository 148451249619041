.remote-caret {
    position: absolute;
    border-left: black;
    border-left-style: solid;
    border-left-width: 2px;
    height: 1em;
}
.remote-caret > div {
    position: relative;
    top: -0.4em;
    font-size: 13px;
    background-color: rgb(250, 129, 0);
    font-family: serif;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    user-select: none;
    color: white;
    padding-left: 2px;
    padding-right: 2px;
    z-index: 3;
    border-radius: 20%;
}
.cm-s-monokai.CodeMirror,
.cm-s-monokai .CodeMirror-gutters {
    background: #24292E !important;
}

.inputBackColor{
    background-color: #24292E !important;
}

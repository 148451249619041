.ant-popover-arrow-content::before {
    background: #334155 !important;
}
.ant-popover-inner {
    background: #334155 !important;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.12), 0px 3px 11px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
}
.ant-popover-title {
    border-bottom: 1px solid #f0f0f0;
}